.login-all {
  height: calc(100% - 54px);
  padding: 0 80px;
}
.login-all > div {
  width: 100%;
  height: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  overflow-y: auto;
}
.login-all > div::-webkit-scrollbar {
  display: none;
}
.login-all-form {
  position: relative;
}
.login-all-form-prefix {
  margin-left: -5px;
  display: flex;
  align-items: center;
}
.login-all-form-suffix-getCode {
  margin-right: -5px;
  width: 90px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #1677ff;
  border-left: 1px solid #D9D9D9;
  cursor: pointer;
}
.login-all-form-suffix-getCodeValue {
  margin-right: -5px;
  width: 90px;
  height: 16px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  border-left: 1px solid #D9D9D9;
}
.login-all-form-tip {
  position: absolute;
  right: 0;
  bottom: 2px;
}
.login-all-form .ant-input {
  border-radius: 2px !important;
}
.login-all-form .ant-input-affix-wrapper {
  border-radius: 2px !important;
}
.login-all-form .ant-form-item {
  margin-bottom: 20px !important;
}
.login-all-form .ant-form-item.marginbottom4 {
  margin-bottom: 4px !important;
}
.login-all-form .ant-form-item-explain-error {
  line-height: 20px;
  transform: translateY(-2px);
}
.login-all-otherlogin {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.login-all-otherlogin-item {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
}
.login-all-otherlogin-item img {
  width: 100%;
  height: 100%;
}
.login-all-otherlogin-item span {
  position: absolute;
  left: 50%;
  top: 32px;
  transform: translateX(-50%);
  width: fit-content;
  min-width: 24px;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  color: #333333;
  line-height: 12px;
}
