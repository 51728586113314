.os-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.os-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.os-ellipsis-more2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.os-tooltip-border {
  border: 0.5px solid #e5e5e5;
  border-radius: 2px;
  box-shadow: 1px 1px 2px 0px rgba(92, 117, 153, 0.1);
}
.os-tooltip-border .ant-tooltip-inner {
  color: #666666;
  min-width: 20px;
  min-height: 20px;
  line-height: 12px;
  padding: 4px;
  font-size: 12px;
  font-weight: 400;
}
.os-y-scrollbar-none {
  overflow-y: auto;
}
.os-y-scrollbar-none::-webkit-scrollbar {
  display: none;
}
.tooltip-max-10 .ant-tooltip-inner {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  line-clamp: 10;
  -webkit-box-orient: vertical;
}
.os-overflow-y {
  overflow-y: auto;
}
.os-overflow-y::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}
.os-overflow-y::-webkit-scrollbar-track {
  background-color: transparent;
}
.os-overflow-y::-webkit-scrollbar-thumb {
  background-color: #ededed;
  border-radius: 4px;
}
.os-overflow-y::-webkit-scrollbar-thumb:hover {
  background-color: #e0e0e0;
}
