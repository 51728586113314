.login {
  width: 100%;
  height: 100vh;
  display: flex;
}
.login-left {
  width: 55%;
  height: 100%;
}
.login-right {
  width: 45%;
  height: 100%;
  background-color: #ffffff;
}
