.record-hostname-gov {
  height: 54px;
  text-align: center;
  padding: 20px;
  line-height: 14px;
  color: #999999;
}
.record-hostname-gov a {
  color: #999999;
}
.record-hostname-gov a:hover {
  color: #1677ff;
}
